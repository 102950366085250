import React, { useEffect, useRef, useState } from 'react';
import useApplicationContext from '../../context/ApplicationContext';
import GreyTextIconBlock from '../blocks/GreyTextIconBlock';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const iconsMap = {
  foodService: 'SvgForkKnife',
  retail: 'SvgRetail',
  hospitality: 'SvgHospitality',
  generalLabour: 'SvgLabour',
  customerService: 'SvgCustomerService',
  salesMarketing: 'SvgSaleMarketing',
  officeAdmin: 'SvgOfficeAdmin',
  healthCare : 'SvgHealthCare',
  fullTime: 'SvgJob',
  partTime: 'SvgJob',

};

const jobTypeKeys = [
  'fullTime',
  'partTime',
  'foodService',
  'retail',
  'hospitality',
  'generalLabour',
  'customerService',
  'salesMarketing',
  'officeAdmin',
  'healthCare'
];

const FilterTagsCarousel = ({ tagClassName = '', className = '' }) => {
  let combinedPreferences = [];

  const { user, setShowUpdateJobTypeModal, setShowUpdateJobLocationModal } =
    useApplicationContext();
  const {
    postalCode,
    distance,
    countries,
    availabilities,
    jobCategories,
    location,
  } = user || {};

  const distanceType = !!user?.countries?.filter((c) => c.key === 'us')[0].value
    ? user.distance < 2
      ? ' mile'
      : ' miles'
    : ' km';

  const _countries = countries?.filter((a) => a.value) || [];
  const _availabilities = availabilities?.filter((a) => a.value) || [];
  const _jobCategories = jobCategories?.filter((a) => a.value) || [];
  const [curIdx, setCurIdx] = useState(0);
  const [filterID, setFilterID] = useState('');
  const slideRef = useRef();

  combinedPreferences = [..._countries, ..._availabilities, ..._jobCategories];
  // combinedPreferences.unshift({ title: postalCode, key: postalCode });
  combinedPreferences.unshift({ title: location?.label, key: location?.label });
  !!distance &&
    combinedPreferences.unshift({
      title: distance + distanceType,
      key: distance,
    });
  useEffect(() => {
    setFilterID(`filter-tags-${Math.floor(Math.random() * 100)}`);
  }, []);
  useEffect(() => {
    if (!!filterID) {
      var content = document.querySelector(`#${filterID}`),
        wrapper = document.querySelector(`#${filterID} .wrapper`),
        shadowLeft = document.querySelector(`#${filterID} .shadow--left`),
        shadowRight = document.querySelector(`#${filterID} .shadow--right`),
        contentScrollHeight = content.offsetWidth - wrapper.scrollLeft;

      wrapper.addEventListener('scroll', function () {
        var currentScroll = this.scrollLeft / contentScrollHeight;
        shadowLeft.style.opacity = currentScroll;
        shadowRight.style.opacity = 1 - currentScroll;
      });
    }
  }, [filterID]);
  // console.log({combinedPreferences})
  return (
    <div
      id={filterID}
      className={`border-l pl-2 ${className} relative`}
    >
      <div className='absolute w-2 h-full shadow--left'></div>
      <div className='absolute w-2 h-full shadow--right'></div>
      <div className='block overflow-x-auto wrapper whitespace-nowrap'>
        {combinedPreferences?.map((item, idx) => {
          console.log(item);
          return (
            <div
              onClick={() => {
                if (jobTypeKeys.includes(item.key)) {
                  setShowUpdateJobTypeModal(true);
                } else {
                  setShowUpdateJobLocationModal(true);
                }
              }}
              key={idx}
              className='inline-block z-10 pt-1.5 overflow-hidden transition-all'
            >
              <GreyTextIconBlock
                key={idx}
                className={tagClassName}
                text={item.title}
                iconName={iconsMap[item.key] || 'SvgLocationPin'}
              />
            </div>
          );
        })}
      </div>
      {/* <Slider
        {...{
          speed: 500,
          // slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          variableWidth: true,
          dots: false,
          centerMode: false,
          centerPadding: `0px 0px 0px 40px`,
          initialSlide: curIdx,
          afterChange: (idx) => {
            setCurIdx(idx);
          },
          className: "wrapper"
        }}
        ref={slideRef}>
        {combinedPreferences?.map((item, idx) => {
          return (
            <div
              key={idx}
              className="z-10 flex flex-row pt-1 overflow-hidden transition-all">
              <GreyTextIconBlock
                key={idx}
                className={tagClassName}
                text={item.title}
                iconName={iconsMap[item.key] || "SvgLocationPin"}
              />
            </div>
          );
        })}
      </Slider> */}
    </div>
  );
};

export default FilterTagsCarousel;
