import React from "react";
import Icons from "../../icons";

const GreyTextIconBlock = ({ text, iconName, className = "" }) => {
  console.log("helloiconName", iconName);
  const Icon = Icons[iconName];
  return (
    <div
      className={`cursor-default flex items-center justify-center bg-gray-100 rounded px-2 py-1.5 mx-1.5 ${className}`}>
      {Icon && (
        <Icon
          className="mr-1 text-gray-500"
          width={20}
          height={iconName == "SvgForkKnife" ? 16 : 20}
        />
      )}
      <p className="flex-1 text-sm whitespace-nowrap">{text}</p>
    </div>
  );
};

export default GreyTextIconBlock;
