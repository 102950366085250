const serverURL =
  process.env.REACT_APP_VERCEL_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PROD
    : process.env.REACT_APP_SERVER_URL_STAGING;
const x_api_key = process.env.REACT_APP_X_API_KEY;

const initialUser = {
  fullName: null,
  phoneNumber: null,
  email: null,
  resume: null,
  notification: false,
  postalCode: null,
  geoLocation: null,
  location: null,
  distance: 0,
  countries: [
    { title: 'Canada', key: 'canada', value: false },
    { title: 'United States', key: 'us', value: false },
  ],
  availabilities: [
    { title: 'Full Time', key: 'fullTime', value: false },
    { title: 'Part Time', key: 'partTime', value: false },
  ],
  jobCategories: [
    { title: 'Food service', key: 'foodService', value: false },
    { title: 'Retail', key: 'retail', value: false },
    { title: 'Hospitality', key: 'hospitality', value: false },
    { title: 'General Labour', key: 'generalLabour', value: false },
    { title: 'Customer Service', key: 'customerService', value: false },
    { title: 'Sales Marketing', key: 'salesMarketing', value: false },
    { title: 'Office Admin', key: 'officeAdmin', value: false },
    { title: "Healthcare", key: "healthCare", value: false },
  ],
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const diffInDaysOfTwoDates = (date1, date2) => {
  const DAY_UNIT_IN_MILLISECONDS = 24 * 3600 * 1000;
  const diffInMilliseconds =
    new Date(date1).getTime() - new Date(date2).getTime();
  const diffInDays = diffInMilliseconds / DAY_UNIT_IN_MILLISECONDS;
  return Math.round(diffInDays);
};

const checkIfDateIsToday = (date) => {
  var today = new Date();
  return today.toDateString() == date.toDateString();
};

const getAmPmTime = (date) => {
  var hours = (date.getHours() + 24) % 12 || 12;
  var ampm = hours >= 12 ? 'am' : 'pm';
  return hours + ':' + ('0' + date.getMinutes()).slice(-2) + ampm;
};

const getDateFormat = (date) => {
  var month = date.getMonth();
  var year = date.getFullYear();
  var day = date.getDate();
  return `${monthNames[month]} ${day}, ${year}`;
};

const storageKey = {
  IS_PROFILE_SETUP: 'is_profile_setup',
  USER: 'user',
  IGNORED_JOBS: 'ignored_jobs',
  SAVED_JOBS: 'saved_jobs',
  APPLIED_JOBS: 'applied_jobs',
  VIEW_EXTERNAL_JOB_MODAL: 'view_external_job_modal',
  VIEW_SWIPE_INFO: 'view_swipe_info',
};

// storing data
const setStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

// getting data
const getStorageItem = (key) => {
  let userData = null;
  try {
    userData = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.log(error);
  }
  return userData;
};

// remove data
const removeStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

// add a value to an array of storage item
const updateStorageItemWithArrayValue = (key, value) => {
  try {
    let arr = getStorageItem(key);
    arr = !arr ? [] : arr;

    if (
      arr.findIndex(
        (i) =>
          i.id === value?.id &&
          i.title === value?.title &&
          i.post_date === value?.post_date &&
          i.guid === value?.guid
      ) === -1
    ) {
      arr.push(value);
      setStorageItem(key, arr);
    }
  } catch (error) {
    console.log(error);
  }
};

// remove a value from storage item with array value
const removeItemFromStorageItemWithArrayValue = (key, value) => {
  try {
    let arr = getStorageItem(key);
    arr = !arr ? [] : arr;
    setStorageItem(
      key,
      arr.filter((i) => i.id !== value?.id)
    );
  } catch (error) {
    console.log(error);
  }
};

const uploadConf = {
  method: 'POST',
  headers: {
    Accept: '*/*',
    'x-api-key': x_api_key,
    // "Content-Type": "multipart/form-data",
    // remove content-tpe from header (ref - https://stackoverflow.com/questions/68643330/pass-data-to-service-in-axios/68643919#68643919)
  },
  mode: 'cors',
};

// upload resume
const uploadUserResume = async (fileContent, updateResume) => {
  let fileName = fileContent.name.replace(/\s+/g, '');
  let now = Date.now();
  let combinedApplicationId = now + fileName;
  let applicationId = combinedApplicationId.slice(0, -4);

  var formData = new FormData();
  formData.append('resume', fileContent, fileName);
  formData.append('applicationId', applicationId);

  let rawResponseUR = await fetch(serverURL + '/api/applicant/uploadresume', {
    ...uploadConf,
    body: formData,
    redirect: 'follow',
  });
  const resUR = await rawResponseUR.json();

  const date = new Date();

  // This is for testing - Does not send to clients server
  // const resumeName = "test.pdf";
  // const resumeLink = "";

  const resumeName = fileName;
  const resumeLink = resUR.resumeObj.fileUrl;
  const uploadedDate = `${
    monthNames[date.getMonth()]
  }.${date.getDate()}.${date.getFullYear()}`;

  updateResume({ name: resumeName, content: resumeLink, uploadedDate });
};

// handle apply job
const applyJob = async ({ job }) => {
  // This is for testing - Does not send to clients server
  // let applicationId = 2;
  // let appliedDate = new Date();
  // return { status: "OK", data: { applicationId, appliedDate } };

  if (!!job) {
    const localUser = getStorageItem(storageKey.USER);
    // console.log('localuser ', localUser)
    let x_api_key = process.env.REACT_APP_X_API_KEY;

    const uploadConfig = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'x-api-key': x_api_key,
        // "Content-Type": "multipart/form-data",
        // remove content-tpe from header (ref - https://stackoverflow.com/questions/68643330/pass-data-to-service-in-axios/68643919#68643919)
      },
      mode: 'cors',
    };

    const sharedConfig = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'x-api-key': x_api_key,
      },
    };
    try {
      let rawResponseAJ = await fetch(
        //serverURL + "/applyforjobs",
        serverURL + '/api/applicant/apply',
        {
          ...sharedConfig,
          body: JSON.stringify({
            employerId: job.employer?.objectId,
            employerName: job.employer?.name,
            jobId: job.objectId,
            jobTitle: job.title,
            name: localUser.fullName,
            email: localUser.email,
            phone: localUser.phoneNumber,
            channel: 'unifier rest APi',
            resumeUploaded: true,
            resumeUrl: localUser.resume.content,

            // employerId: "gUELbsqdFC",
            // employerName: "Swob Internal Employer",
            // jobId: "xoEYLaFEOj",
            // jobTitle: "Cashier 1",
            // name: "web test applicant",
            // email: "gbo@dotfusion.com",
            // phone: "987654321",
            // channel: "unifier rest APi"
          }),
        }
      );
      const resAJ = await rawResponseAJ.json();
      // console.log("res aj ", resAJ);
      if (resAJ.error) {
        return { status: 'ERROR', error: resAJ.error };
      } else {
        const { applicationId } = resAJ;

        const appliedDate = new Date().toISOString();
        // call update user information end point.
        let rawResponseUP = await fetch(serverURL + '/api/applicant/update', {
          ...sharedConfig,
          body: JSON.stringify({
            applicationId: applicationId,
          }),
        });
        const resUP = await rawResponseUP.json();

        if (resUP.error) {
          return { status: 'ERROR', error: resUP.error };
        } else {
          // fbq capture event
          window.fbq('track', 'jobApplied', {
            applicantName: localUser.fullName,
            email: localUser.email,
            phoneNumber: localUser.phoneNumber,
            jobTitle: job.title,
          });

          return { status: 'OK', data: { applicationId, appliedDate } };
        }
      }
    } catch (error) {
      console.log({ error });
      return { status: 'ERROR', error: error };
    }
  } else {
    return { status: 'ERROR', error: 'Job Data is required' };
  }
};

const getSingleJobData = async ({ jobId }) => {
  let jobData = null;
  try {
    let rawResponse = await fetch(serverURL + '/job/' + jobId);
    if (rawResponse.status === 200) {
      let json = await rawResponse.json();
      jobData = json;
    }
  } catch (error) {
    console.log({ error });
  }
  return jobData;
};

export {
  initialUser,
  monthNames,
  diffInDaysOfTwoDates,
  checkIfDateIsToday,
  getAmPmTime,
  getDateFormat,
  storageKey,
  setStorageItem,
  getStorageItem,
  removeStorageItem,
  updateStorageItemWithArrayValue,
  removeItemFromStorageItemWithArrayValue,
  applyJob,
  getSingleJobData,
  uploadUserResume,
};
