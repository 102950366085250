import useApplicationContext from "../../context/ApplicationContext";
import ButtonCheck from "../../common/blocks/ButtonCheck";
import IconBlocks from "../../common/blocks/IconBlocks";

const StepThree = () => {
  const { user, updateUserValue } = useApplicationContext();
  const availabilities = user.availabilities;
  const jobCategories = user.jobCategories;

  const handleAvailabilitiesChange = (key, value) => {
    const _updated = availabilities.map((a) =>
      a.key == key ? { ...a, value } : a
    );
    updateUserValue("availabilities", _updated);
  };

  const handleJobCategoriesChange = (key, value) => {
    const _updated = jobCategories.map((j) =>
      j.key == key ? { ...j, value } : j
    );
    updateUserValue("jobCategories", _updated);
  };

  return (
    <div className="w-full mb-auto">
      <p className="mb-4 text-base font-medium">
        Work availability (Can select both)
      </p>

      {!!availabilities
        ? availabilities.map((a, idx) => (
            <ButtonCheck
              key={idx}
              title={a.title}
              value={a.value}
              onChange={(value) => handleAvailabilitiesChange(a.key, value)}
            />
          ))
        : null}

      <p className="mb-4 text-base font-medium">
        Job Categories (Select all that apply)
      </p>

      {!!jobCategories
        ? jobCategories.map((j, idx) => (
            <ButtonCheck
              key={idx}
              title={j.title}
              value={j.value}
              onChange={(value) => handleJobCategoriesChange(j.key, value)}
              icon={IconBlocks({
                key: j.key,
                className: `${j.value ? "text-primary-blue" : "text-gray-500"}`,
              })}
            />
          ))
        : null}
    </div>
  );
};

export default StepThree;
