import React from "react";
import ModalWrapper from "./ModalWrapper";
import Icons from "../../icons";
import { storageKey, setStorageItem } from "../../helpers/utils";

const JobExternalModal = (props) => {
  const { data = {}, onCrossClick } = props;

  const handleStopSeeingMessage = async () => {
     // Set the storage item to stop seeing the message
     await setStorageItem(storageKey.VIEW_EXTERNAL_JOB_MODAL, false);

    // Dismiss the modal first
    if (onCrossClick) onCrossClick();

   

    // // Redirect to the external job URL
    // if (data?.url) {
    //   window.open(data.url, "_blank", "noopener noreferrer");
    // }
  };

  return (
    <ModalWrapper {...props}>
      <div className="flex flex-col items-center mx-auto max-w-external-modal">
        <Icons.SvgExternalJob className="mr-2 text-3xl" height={100} width={100} />
        <p className="text-xl font-semibold text-center py-7">
          This job required you to apply from external link
        </p>
        <a
          href={data?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full py-3 text-center text-white rounded cursor-pointer bg-primary-blue">
          Visit now
        </a>

        <p
          className="text-sm text-center text-gray-400 cursor-pointer py-7"
          onClick={handleStopSeeingMessage}>
          Stop seeing this message
        </p>
      </div>
    </ModalWrapper>
  );
};

export default JobExternalModal;
